import { ReactNode, FC, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { PATH_AUTH } from '../../shared/constants/path'
import { useAuthStore } from '../../shared/services/authStore'
import {
  // errorAuthSelector,
  // logOutSelector,
  tokenSelector,
  userSelector,
} from '../../shared/services/authStore/selectors'
import OpacityLoader from '../../shared/components/OpacityLoader/OpacityLoader.tsx'

interface AuthGuardProps {
  children: ReactNode
}

interface QueryParams {
  returnTo?: string
  bundle?: string
}

const parseQueryParams = (search: string): QueryParams => {
  const params = new URLSearchParams(search)
  return {
    returnTo: params.get('returnTo') || '',
    bundle: params.get('bundle') || '',
  }
}

const buildRedirectUrl = (
  returnTo: string | undefined,
  bundle: string | undefined,
): string => {
  if (!returnTo) return ''
  return `${returnTo}${bundle ? `?bundle=${bundle}` : ''}`
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const location = useLocation()

  const token = useAuthStore(tokenSelector)
  const user = useAuthStore(userSelector)
  const [isLoading, setIsLoading] = useState(true)
  const { returnTo, bundle } = parseQueryParams(location.search)
  const redirectUrl = buildRedirectUrl(returnTo, bundle)
  // const authError = useAuthStore(errorAuthSelector)
  // const logOut = useAuthStore(logOutSelector)

  useEffect(() => {
    if (redirectUrl) {
      setIsLoading(false)
    } else {
      const timeoutId = setTimeout(() => {
        setIsLoading(false)
      }, 1000)
      return () => clearTimeout(timeoutId)
    }
  }, [token, user, redirectUrl])

  // if (authError) {
  //   const errorMessage =
  //     typeof authError === 'string' ? authError : authError?.message
  //   if (
  //     errorMessage &&
  //     !errorMessage.includes('auth/multi-factor-auth-required')
  //   ) {
  //     logOut()
  //     return (
  //       <Navigate
  //         to={redirectUrl || PATH_AUTH.signUp}
  //         state={location}
  //         replace
  //       />
  //     )
  //   }
  // }

  if (token === undefined || token === null || isLoading) {
    return <OpacityLoader />
  }

  if (!token) {
    return (
      <Navigate to={redirectUrl || PATH_AUTH.root} state={location} replace />
    )
  }
  const isUserPartner = user?.role === 'PARTNER'

  const isFinishOnboarding = Boolean(
    user?.role && !isUserPartner && user?.firstName,
  )

  if (!isFinishOnboarding) {
    if (!user?.role || isUserPartner) {
      return <Navigate to={redirectUrl || PATH_AUTH.choose_role} replace />
    }
    return <Navigate to={redirectUrl || PATH_AUTH.introduce} replace />
  }

  return <>{children}</>
}

export default AuthGuard

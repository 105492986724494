import { Modal as AntModal, Col, ModalProps, Row } from 'antd'
import { ReactNode } from 'react'
import Button from '../../ui/Button'
import Title from '../Typography/Title'
import Text from '../Typography/Text'
import { useTheme } from '../../../app/theme/useTheme'

interface IModal extends ModalProps {
  setOpen?: (key: boolean) => void
  handleOk?: ModalProps['onOk']
  cta?: string
  description?: ReactNode
  cancelFalse?: boolean
  isDisabled?: boolean
  isNotBranded?: boolean
  buttonWidth?: boolean
  isBlackButton?: boolean
  isFooterHidden?: boolean
  isVerticalButtons?: boolean
  contentPadding?: number | string
}

const Modal = ({
  open,
  confirmLoading,
  setOpen,
  handleOk,
  children,
  title,
  cta,
  description,
  okType,
  cancelText,
  cancelFalse,
  isDisabled,
  isNotBranded,
  onCancel,
  buttonWidth,
  isBlackButton,
  isFooterHidden,
  isVerticalButtons,
  contentPadding,
  ...props
}: IModal) => {
  const theme = useTheme()

  const handleCancel = () => {
    setOpen ? setOpen(false) : null
  }

  return (
    <AntModal
      styles={{
        mask: {
          backdropFilter: 'blur(7.5px)',
          backgroundColor: theme.colorFillContent,
        },
        header: {
          ...(title ? { marginBottom: theme.paddingLG } : { display: 'none' }),
        },
        footer: {
          marginTop: isFooterHidden ? 0 : theme.marginLG,
        },
        content: {
          padding: contentPadding || theme.paddingXL,
        },
      }}
      title={
        <Row justify="center" gutter={[0, theme.paddingXS]}>
          <Col span={24}>
            <Row justify="center">
              <Title level={2}>{title}</Title>
            </Row>
          </Col>
          {description && (
            <Text
              type="secondary"
              size="lg"
              style={{
                textAlign: 'center',
                fontWeight: theme.fontWeightRegular,
              }}
            >
              {description}
            </Text>
          )}
        </Row>
      }
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={cancelFalse ? () => {} : onCancel ? onCancel : handleCancel}
      footer={
        !isFooterHidden && [
          <Row
            {...(cancelText && {
              justify: isBlackButton ? 'end' : 'space-between',
            })}
            gutter={[theme.paddingLG, 0]}
            key={cta}
            style={{
              flexDirection: isVerticalButtons ? 'column-reverse' : 'row',
              gap: isVerticalButtons ? theme.margin : undefined,
            }}
          >
            {cancelText && (
              <Col
                span={isBlackButton ? undefined : isVerticalButtons ? 24 : 12}
              >
                <Button block onClick={onCancel ? onCancel : handleCancel}>
                  {cancelText}
                </Button>
              </Col>
            )}
            <Col
              span={
                isBlackButton
                  ? undefined
                  : cancelText
                    ? isVerticalButtons
                      ? 24
                      : 12
                    : 24
              }
            >
              {isNotBranded ? (
                <Button
                  block
                  loading={confirmLoading}
                  onClick={handleOk}
                  style={{
                    width: buttonWidth ? '25%' : undefined,
                    backgroundColor: isBlackButton ? 'black' : undefined,
                    color: isBlackButton ? 'white' : undefined,
                  }}
                >
                  {cta}
                </Button>
              ) : (
                <Button
                  block
                  type={okType === 'danger' ? undefined : okType || 'branded'}
                  danger={okType === 'danger'}
                  loading={confirmLoading}
                  onClick={handleOk}
                  disabled={isDisabled}
                >
                  {cta}
                </Button>
              )}
            </Col>
          </Row>,
        ]
      }
      {...props}
    >
      {children}
    </AntModal>
  )
}

export default Modal
